.content :global p:not(.label) {
    @apply mt-2 sm:text-lg !important;
}

.content :global ul {
    @apply mt-2 list-none pl-6 text-sm !important;
}

.content :global ul li::marker {
    content: "•  ";
}

.content :global ul li:not(:first-child) {
    @apply mt-2;
}
